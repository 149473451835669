import { useState } from 'react';
import './App.css';
import Gallery from './Gallery';
import LoadScreen from './LoadScreen';

const App = () => {
    const [invertBg, setInvertBg] = useState(false);
    const [viewGallery, setViewGallery] = useState(false);

    const handleChangeBackground = () => {
        setInvertBg((prevState) => !prevState);

        if (!invertBg) {
            document.body.style.backgroundColor = '#d2cdc5';
        } else {
            document.body.style.backgroundColor = '#282c34';
        }
    };

    const handleGoToGallery = () => {
        setViewGallery(true);
        if (!viewGallery) {
            document.body.style.overflow = 'visible';
        }
    };

    return (
        <div>
            {!viewGallery && (
                <LoadScreen
                    changeBackground={handleChangeBackground}
                    invertBg={invertBg}
                    goToGallery={handleGoToGallery}
                    viewGallery={viewGallery}
                />
            )}
            <h2 className={invertBg ? 'invertedBg' : ''}>Te Amo Danny ♡</h2>
            <main id='main'>
                <Gallery />
            </main>
        </div>
    );
};

export default App;
