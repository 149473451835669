import { useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

// import optional lightbox plugins
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import photosData from '../photosData.json';

const images = require.context('../images', true);

const Gallery = () => {
    // eslint-disable-next-line no-unused-vars
    const [photos, _] = useState(() => photosData);
    const [index, setIndex] = useState(-1);

    return (
        <>
            <PhotoAlbum
                photos={photos}
                componentsProps={{
                    containerProps: { className: 'galleryContainer' },
                    imageProps: { loading: 'eager' },
                }}
                layout='rows'
                onClick={({ index }) => setIndex(index)}
                targetRowHeight={(containerWidth) => {
                    if (containerWidth >= 1200) return containerWidth / 4;
                    if (containerWidth >= 600) return containerWidth / 3;
                    if (containerWidth >= 300) return containerWidth / 4;
                    return containerWidth / 2;
                }}
                renderPhoto={({
                    imageProps: { src, alt, style, ...restImageProps },
                }) => (
                    <img
                        src={images(src)}
                        alt={alt}
                        style={style}
                        {...restImageProps}
                    />
                )}
            />

            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                controller={{ closeOnBackdropClick: true }}
                render={{
                    slide: ({
                        slide: {
                            src,
                            width,
                            height,
                            title = '',
                            alt = '',
                            ...rest
                        },
                    }) => {
                        console.log('slide', rest);
                        return (
                            <img
                                src={images(src)}
                                height={height}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                }}
                                title={title}
                                alt={alt}
                                {...rest}
                            />
                        );
                    },
                }}
                // enable optional lightbox plugins
                plugins={[Slideshow, Zoom]}
            />
        </>
    );
};

export default Gallery;
