import './CoolText.css';

const CoolText = ({ invertBg }) => (
    <>
        <div className={`coolTextContainer ${invertBg ? 'invertBg' : ''}`}>
            <h2>Te Am❤ Danny</h2>
            <h2>Te Am❤ Danny</h2>
        </div>
    </>
);
export default CoolText;
