import { useState } from 'react';
import CoolText from './CoolText';
import ExpandingCircle from './ExpandingCircle';
import './LoadScreen.css';

const LoadScreen = ({
    changeBackground,
    invertBg,
    goToGallery,
    viewGallery,
}) => {
    return (
        <div
            className={`loadScreenContainer ${invertBg ? 'invertBg' : ''} ${
                viewGallery ? 'viewGallery' : ''
            }`}
        >
            <ExpandingCircle
                changeBackground={changeBackground}
                invertBg={invertBg}
            >
                <CoolText invertBg={invertBg} />
                <div className='menu'>
                    <button
                        onClick={() => {
                            setTimeout(() => {
                                goToGallery();
                            }, 1000);
                        }}
                        className='menu-item'
                    >
                        Galería
                    </button>
                </div>
            </ExpandingCircle>
        </div>
    );
};

export default LoadScreen;
