import { useState, useEffect, useCallback, useRef } from 'react';
import './ExpandingCircle.css';

const ExpandingCirlce = ({ children, changeBackground }) => {
    const cursorRef = useRef(null);
    const cursorRef2 = useRef(null);

    const [coords, setCoords] = useState({
        x: 0,
        y: 0,
    });
    const endX = useRef(0);
    const endY = useRef(0);

    const handleMouseMove = useCallback((event) => {
        const { clientX, clientY } = event;
        setCoords({ x: clientX, y: clientY });
        if (cursorRef.current !== null) {
            cursorRef.current.style.top = `${clientY}px`;
            cursorRef.current.style.left = `${clientX}px`;
            cursorRef2.current.style.top = `${clientY}px`;
            cursorRef2.current.style.left = `${clientX}px`;
        }
        endX.current = clientX;
        endY.current = clientY;
    }, []);

    // Outer Cursor Animation Delay
    const previousTimeRef = useRef(null);
    const requestRef = useRef(null);
    const trailingSpeed = 8;
    const animateOuterCursor = useCallback(
        (time) => {
            if (previousTimeRef.current !== undefined) {
                coords.x += (endX.current - coords.x) / trailingSpeed;
                coords.y += (endY.current - coords.y) / trailingSpeed;
                if (cursorRef.current !== null) {
                    cursorRef.current.style.top = `${coords.y}px`;
                    cursorRef.current.style.top = `${coords.y}px`;
                    cursorRef2.current.style.left = `${coords.x}px`;
                    cursorRef2.current.style.left = `${coords.x}px`;
                }
            }
            previousTimeRef.current = time;
            requestRef.current = requestAnimationFrame(animateOuterCursor);
        },
        [requestRef] // eslint-disable-line
    );

    // Outer cursor RAF setup / cleanup
    useEffect(() => {
        requestRef.current = requestAnimationFrame(animateOuterCursor);
        return () => {
            if (requestRef.current !== null) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [animateOuterCursor]);

    // Cursors Hover/Active State
    const [invert, setInvert] = useState(false);

    const handleOnClick = () => {
        changeBackground();
        setInvert((prevState) => !prevState);
    };

    return (
        <div
            id='expandingCircleContainer'
            onMouseMove={handleMouseMove}
            // onMouseDown={() => setIsActive(true)}
            // onMouseUp={() => setIsActive(false)}
            onClick={handleOnClick}
        >
            <div
                ref={cursorRef}
                className={`circle ${invert ? 'invert' : 'normal'}`}
            />
            <div
                ref={cursorRef2}
                className={`circle ${invert ? 'normal' : 'invert'}`}
            />
            {children}
        </div>
    );
};

export default ExpandingCirlce;
